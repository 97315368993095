import React from "react";
import { Col, Row } from "react-grid-system";
import pageIcon from "../../images/discussion-guide/personalize/ddg-art.svg";
import wakixLogo from "../../images/wakix-logo.svg";
import FooterPrint from "./footer";
import "./print.scss";
import iconImg1 from "../../images/icon-1.png";
import iconImg2 from "../../images/icon-2.png";
import iconImg3 from "../../images/icon-3.png";
// import HcpIsi from "../hcp/isi/isi-content";

const PrintPdf = React.forwardRef((props, ref) => {
  return (
    <div className="print-wrapper" ref={ref}>
      <div className="print-block">
        <div className="centered-content">
          <div className="page-content">
            <div className="media">
              <img src={pageIcon} className="header-icon" alt="Page Icon" />
              <div className="text-content">
                <h3>For Adults Living With Narcolepsy</h3>
                <h1>My Conversation Plan</h1>
                <p>
                  You answered a few questions about how you're managing excessive daytime sleepiness (EDS) or cataplexy as an adult living with narcolepsy. Below you'll find a summary of your responses. {" "}
                  <strong>
                    Use them as a guide during your next conversation with your
                    healthcare provider.
                  </strong>
                </p>
              </div>
            </div>
            <div className="logo">
              <img src={wakixLogo} alt="Logo" />
            </div>
          </div>

          <div className="form-data">
            <Row>
              <Col lg={6}>
                <div className="content-wrap ht">
                  <div className="head-title">
                    <img src={iconImg1} alt="icon" />
                    <span>My Life With Narcolepsy</span>
                  </div>
                  <div className="form-list">
                    <div className="results">
                      <p className="head">
                        In my day-to-day life, EDS or cataplexy in narcolepsy
                      </p>
                      <div className="response">
                        <ul className="list">
                          {props.data["step-1-q-1"]?.map(
                            (item) =>
                              item !== "Other" && (
                                <li key={item} className="st-line">
                                  {item}
                                </li>
                              )
                          )}
                          {props.others?.Q1_A4_other && (
                            <li className="st-line">
                              {props.others?.Q1_A4_other}
                            </li>
                          )}
                        </ul>
                      </div>
                    </div>
                    <div className="results">
                      <p className="head">
                        How much does EDS or cataplexy in narcolepsy impact your
                        day-to-day life?
                      </p>
                      <div className="response">
                        <ul className="list">
                          <li>{props.data["step-1-q-2"]}</li>
                        </ul>
                      </div>
                    </div>
                    <div className="results">
                      <p className="head">
                        What is your top goal for managing your EDS or cataplexy
                        in narcolepsy?
                      </p>
                      <div className="response">
                        <ul className="list">
                          <li style={{wordBreak:'break-word'}}>{props.data["step-1-q-3"]}</li>
                        </ul>
                      </div>
                    </div>
                    <div className="results">
                      <p className="head">
                        How often do you feel you reach this goal?
                      </p>
                      <div className="response">
                        <ul className="list">
                          <li>{props.data["step-1-q-4"]}</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
              <Col lg={6}>
                <div className="content-wrap ht">
                  <div className="head-title">
                    <img src={iconImg2} alt="icon" />
                    <span>My Treatment Experience</span>
                  </div>
                  <div className="form-list">
                    <div className="results">
                      <p className="head">
                        When it comes to my treatment plan, I am
                      </p>
                      <div className="response">
                        <ul className="list">
                          {props.data["step-2-q-1"]?.map(
                            (item) =>
                              item !== "Other" && (
                                <li key={item} className="st-line">
                                  {item}
                                </li>
                              )
                          )}
                          {props.others?.Q5_A7_other && (
                            <li className="st-line">
                              {props.others?.Q5_A7_other}
                            </li>
                          )}
                        </ul>
                      </div>
                    </div>
                    <div className="results">
                      <p className="head">
                        How satisfied are you with your current treatment plan?
                      </p>
                      <div className="response">
                        <ul className="list">
                          <li>{props.data["step-2-q-2"]}</li>
                        </ul>
                      </div>
                    </div>
                    <div className="results">
                      <p className="head">
                        How ready are you to consider a change to your
                        treatment?
                      </p>
                      <div className="response">
                        <ul className="list">
                          <li>{props.data["step-2-q-3"]}</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </div>

          <div className="checkbox-block form-data">
            <div className="content-wrap">
              <div className="head-title">
                <img src={iconImg3} alt="icon" />
                <span>
                  My Questions About WAKIX<sup>&reg;</sup> (pitolisant)
                </span>
              </div>
              <Row className="row">
                <Col xs={5}>
                  <div class="question-wrapper" attr-type="checkbox">
                    <div class="checkbox_set">
                      {props.Q8.map(
                        (item, index) =>
                          index < 3 &&
                          props.data["step-3-q-1"] && (
                            <fieldset
                              class={`checkbox_field ${props.data["step-3-q-1"]?.includes(item)
                                ? "selected"
                                : ""
                                }`}
                            >
                              <label for="step-3-q-1-1">
                                <input
                                  type="checkbox"
                                  id="step-3-q-1-1"
                                  name="step-3-q-1[]"
                                  value={item}
                                />
                                <span class="noselect">{item}</span>
                              </label>
                            </fieldset>
                          )
                      )}
                    </div>
                  </div>
                </Col>
                <Col xs={7}>
                  <div class="question-wrapper" attr-type="checkbox">
                    <div class="checkbox_set">
                      {props.Q8.map(
                        (item, index) =>
                          index >= 3 &&
                          props.data["step-3-q-1"] && (
                            <fieldset
                              class={`checkbox_field ${props.data["step-3-q-1"]?.includes(item)
                                ? "selected"
                                : ""
                                }`}
                            >
                              <label for="step-3-q-1-1">
                                <input
                                  type="checkbox"
                                  id="step-3-q-1-1"
                                  name="step-3-q-1[]"
                                  value={item}
                                />
                                <span class="noselect">{item}</span>
                              </label>
                            </fieldset>
                          )
                      )}
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </div>
        <div>
          <section className="content-container isi-section">
            <div className="centered-content isi-content">
              <h2 className="isi-h2">Indications and Usage</h2>
              <span>WAKIX is a prescription medicine used to treat:</span>
              <ul className="bullet-list no-show-on-sticky">
                <li>
                  excessive daytime sleepiness (EDS) or cataplexy in adults
                  with narcolepsy.
                </li>
                <li>
                  excessive daytime sleepiness (EDS) in children 6 years of
                  age and older with narcolepsy.
                </li>
              </ul>
              <h2 className="isi-h2 normal">Important Safety Information</h2>
              <p>
                <strong>
                  Do not take WAKIX if you are allergic to pitolisant or any ingredient in WAKIX, or if you have severe
                  liver disease.
                </strong>
              </p>
              <p>
                <strong>
                  WAKIX® (pitolisant) can cause a change in the electrical activity of the heart known as QT prolongation.
                </strong>{" "}
                This is a heart rhythm problem that can lead to an abnormal heartbeat. You have a higher chance of getting QT prolongation if you have certain heart or other medical conditions, or if you take WAKIX with certain medicines. Tell your healthcare provider right away if you have a change in your heartbeat or if you feel dizzy or faint while taking WAKIX.
              </p>
              <p>
                <strong>Tell your healthcare provider about all your medical conditions,</strong>{" "}
                including if you have any heart, liver, or kidney problems, or problems with blood levels of your
                electrolytes, such as potassium or magnesium.
              </p>
              <p>
                <strong>Tell your healthcare provider about all the medicines you take or plan to take,</strong>{" "}
                including prescription and over-the-counter medicines, vitamins, and herbal supplements. Taking WAKIX with certain medicines may cause side effects or affect the way each other works.
              </p>
              <p>
                Hormonal birth control methods may not work while taking WAKIX. Use an alternative non-hormonal birth control method during treatment with WAKIX and for at least 21 days after stopping WAKIX.
              </p>
              <p>
                <strong>Tell your healthcare provider if you are pregnant or planning to become pregnant.</strong>{" "}
                You are encouraged to enroll in the WAKIX pregnancy registry if you become pregnant while taking WAKIX. The registry collects information about the health of you and your baby. To enroll or obtain information from the registry, call{" "} <nobr>1-800-833-7460</nobr>
              </p>
              <p>
                <strong>The most common side effects of WAKIX in adults</strong>{" "} include insomnia, nausea, and anxiety.
              </p>
              <p>
                <strong>The most common side effects of WAKIX in children</strong>{" "} include headache and insomnia.
              </p>
              <p>
                These are not all the possible side effects of WAKIX. Call your healthcare provider for medical advice about side effects.
              </p>
              <p>
                It is not known if WAKIX is safe and effective to treat excessive daytime sleepiness in children under 6 years of age with narcolepsy or to treat cataplexy in people under 18 years of age with narcolepsy.
              </p>
              <p>
                You are encouraged to report negative side effects of prescription drugs to the FDA. Visit <a href="https://www.fda.gov/safety/medwatch-fda-safety-information-and-adverse-event-reporting-program" target="_blank" rel="noopener noreferrer">www.fda.gov/medwatch</a>, or call 1-800-FDA-1088. You can also report negative side effects to Harmony Biosciences at 1-800-833-7460.
              </p>
              <p className="strong">
                Please see accompanying <a href="/pdf/wakix-tablets-pi.pdf" target="_blank" rel="noopener noreferrer">Full Prescribing Information</a>.
              </p>
            </div>
          </section>
        </div>
      </div>
      <div className="foot-bottom">
        <div className="foot-wrap">
          <FooterPrint />
        </div>
        <div className="foot-logo">
          <img src={wakixLogo} alt="Logo" />
        </div>
      </div>
    </div>
  );
});

export default PrintPdf;
